import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
import { TwoCol, Main, Aside, JobHeader } from "../../components/felix-mdx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TwoCol mdxType="TwoCol">
      <Main mdxType="Main">
        <p>{`...to Felix’ homepage.`}</p>
        <ul>
          <li parentName="ul">{`Interested in my professional background? `}<a parentName="li" {...{
              "href": "./resume/"
            }}>{`See my resume`}</a>{`.`}</li>
          <li parentName="ul">{`Curious about my work? `}<a parentName="li" {...{
              "href": "./projects/"
            }}>{`Check my projects`}</a>{`.`}</li>
          <li parentName="ul">{`Questions or comments? `}<a parentName="li" {...{
              "href": "mailto:flx@bertram-family.com?subject=Homepage"
            }}>{`Leave me a message`}</a>{`.`}</li>
        </ul>
      </Main>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "360px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/91dcc2d2cca0f8fd294be4a6ebe4818c/f21e7/felix_360.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "84.66666666666669%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAD4ElEQVQ4y13U+1NUZRjA8f0T+qGZ0rFCI4swm5ISRqcZ1Ozm4BSipssMOmBxMbkIZKZCIEZArFx0QsYLsLRyV1ECuYkVJEgwgimGgIgg192z5+zZq9/mLC1uvTPPnDPnvO9nnud9zntUNpsN97BaLTgcT1DGk/kLDsBstmA2m7FY5q/u97IsYzKZnKGyWq24QplgtzswzDzmRtMlzmUnU5L3PV1t9Tjsdmx2u3OxO+qOSZL0FFQm2ewOJh4OkR63C7X/Cj5b8xof+XgS4OdFamwos9OTC6gLcwdFUUKlQBazGbvDwcToEMeiPidigw8Jn7xHStB6dvp68c4ri1m55BnyUhJxOBzOxf/HJMkNVF4o43pdFfs+Xs1YUTZGXT5zJRpupkXx4471vLXseUIC1mHUz+Fa4wIVTAmjUUSlpK48VEa19jQ5X2yB3y8iNlVivHSWx0UZ9OUdJGzData87c39wb8XsnTHRNGEILhASXKCv7W1UpgQCh1XkJurESoLaT4USWl0MDVHDxC96X1Gum9gAyRRdDbBFUq5gsGIypW60pjJqSkyo0Ppyk/lWkosg7mHKIsOpvZIBLeKs2k4sI+Rq7VYAVEQ/gMajRIGvfAUlJWNttrob7zMgDaH3oIfeFSkoT8jEUGr4a+iPH7JSqXkeJazGpMzK3GhGUq5egV07YUywWSxYp8dx6qUW3KCKV0BFeFqqsK3056RyIXMZLyWLkWrq0aWzVjM8gJsMBiZmzW4gbIZeWaUkdqfGMg7zO3Mr2lPP0htTAi60EAakr5EF7uLjSs8WeTxBp9uDaOle5gJwca0XkQwCMzN6t1BC9LkIGN1p8jd7E91rJpHBanczjxCZ0oMpftD0Wz1R+2/Fo9XV7HohddJK7pG2yi03ZO4O2Zi1j1D2WxBmHyA3N9Iy+EwUjb5kR+ppkvzHdqEcDRh2zkeuJY4tRpPbz+WLV9FVlkHV4egqtdIZec0Hf1T86DSJQWUDdOM/VrBH4UppO8N59nnXuTbHZupSdpLWfxuyr/aRk5aNkteXonH8nfJu3KH2js2yroNnLk+jq5lGJX076Yqn43ykxlsb+JCgYY9SVoWv+TF7m3B1B1L4FZmDK2aZM7pmlkXGEnCiQZ+vilQ3DFLeY9IcccMpxuHUSmYcpSsFiuSJDN49x6F51vZEXcSb98AQmKy+PCDQGozkokOi2L/0bNkX75PzQBU9IicbHxIVZ8ZbaeeU40P5s+y3W7HZLIwPT5OeWU9wd8UExSRgX9QPHvSL7IlvpA3fTfi4x9I6vleznToqeyTKf/TSG79CGU9EqWdBgoaR/kHZB9Om3/tG4gAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Felix Bertram",
                "title": "Felix Bertram",
                "src": "/static/91dcc2d2cca0f8fd294be4a6ebe4818c/f21e7/felix_360.png",
                "srcSet": ["/static/91dcc2d2cca0f8fd294be4a6ebe4818c/5a46d/felix_360.png 300w", "/static/91dcc2d2cca0f8fd294be4a6ebe4818c/f21e7/felix_360.png 360w"],
                "sizes": "(max-width: 360px) 100vw, 360px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Aside>
    </TwoCol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      